<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase">
                <i18n-t keypath="register.verify_resend.title" />
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="text-center">
                    <i18n-t keypath="register.verify_resend.header">
                        <template v-slot:email>
                            <b>{{ user.email }}</b>
                        </template>
                    </i18n-t>
                </p>
                <p class="text-center mt-2 mb-6">
                    <i18n-t keypath="register.verify_resend.subheader" />
                </p>
                <button
                    class="mt-4 button bg-gradient-to-r from-gradient-orange to-gradient-red w-full p-4 rounded text-white font-medium"
                    v-on:click="resend_verification_email">
                    <i18n-t keypath="register.verify_resend.submit" />
                </button>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from "@/wrappers/Guest";
import { resendVerificationEmail } from "@/helper/api/RegistrationHelper";
import messages from "@/helper/store/messageHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    name: "Resend",
    components: {
        Guest,
    },
    computed: {
        user: function(){
            return this.$store.getters['getUser'];
        },
    },
    methods: {
        resend_verification_email(){
            setLoading(true);

            resendVerificationEmail()
                .then(()=>{
                    messages.success($t('register.verify_resend.success'));
                })
                .catch((err)=>{
                    messages.error($t('register.verify_resend.failure'), err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }
}
</script>